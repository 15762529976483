import React from 'react';
import { Link } from '@imtbl/imx-sdk';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

const Logout = () => {
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  const { t } = useTranslation();

  // Function to handle the "logout" process
  const handleLogout = async () => {
    try {
      const link = new Link('https://link.sandbox.x.immutable.com');

      // Clear relevant local storage items
      localStorage.removeItem('walletAddress');
      localStorage.removeItem('walletEmail');
      localStorage.removeItem('walletNetwork');
      localStorage.removeItem('walletStark');

      // Optionally, set default values in case of a logout
      localStorage.setItem('walletAddress', '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
      localStorage.setItem('walletEmail', '');

      // Reload the page or redirect to another page after logout
      window.location.reload();

    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <div onClick={handleLogout} style={{ cursor: 'pointer', color: '#D9D9D9' }}>
      {/* The div element with an onClick handler */}
      {t('Logout')}
    </div>
  );
};

export default Logout;
